import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioItem from "../components/portfolio_item"
import HeaderIAm from "../components/headerIAm"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomeFeatures {
      allWorksJson(filter: { feature: { eq: "1" } }) {
        edges {
          node {
            awards
            category
            client
            cover {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date
            description
            feature
            media {
              src
            }
            name
            prefix
            tech
            id_project
            id
            link
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" description="Christian's personal website" />
      <HeaderIAm></HeaderIAm>

      <div className="basic-portfolio-area ptb-90">
        <div className="container">
          <div id="portfolio-grid" className="row-portfolio portfolio-style-2">
            {data.allWorksJson.edges.map(i => {
              return (
                <PortfolioItem
                  key={i.node.id_project}
                  classItem="portfolio-item graphic"
                  className="portfolio-item graphic"
                  title={i.node.name}
                  type={i.node.category}
                  link={i.node.prefix}
                  image={i.node.cover}
                />
              )
            })}
          </div>
        </div>
        <div className="view-more mt-20 text-center">
          <Link className="btn btn-large" to="/portfolio">
            View More
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
