import React from "react"
import { Link } from "gatsby"
import ReactRotatingText from "react-rotating-text"

const HeaderIAm = () => (
  <div className="ptb-120 border-t-b">
    <div className="container">
      <div className="slider-content">
        <h2 className="cd-headline clip is-full-width">
          Hello, I am
          <span className="cd-words-wrapper">
            <ReactRotatingText
              items={["a Developer", "a Hardware Maker", "a Code Breaker"]}
            ></ReactRotatingText>
          </span>
        </h2>
        <p>Hardware Lead Developer</p>
        <Link className="btn" to="/aboutme">
          About Me
        </Link>
      </div>
    </div>
  </div>
)

export default HeaderIAm
